/*
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-03 17:29:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\api\user.js
 */
import axios from "axios";
import { post, fetch, patch, put } from "@/utils/http";

//获取积分提现列表
export function getIntegralWithdrawalList(params) {
  return axios.post("/wlynIntegralWithdrawal/getIntegralWithdrawalList", 
    params,
  );
}
//发放积分
export function grantIntegral(params) {
  return axios.post("/wlynIntegralWithdrawal/grantIntegral", params);
}

// 编辑积分提现记录
export function updateIntegralWithdrawal(params) {
  return post("/wlynIntegralWithdrawal/updateIntegralWithdrawal", params);
}

//获取积分详情
export function selectById(params) {
  return axios.get("/wlynIntegralWithdrawal/selectById", { params });
}

//确认导入
export function confirmImport(params) {
  return axios.post(`/wlynIntegralWithdrawal/confirmImport`, params, {
    timeout: 180000,
  });
}

//查询发放金额总数
export function countIntegralWithdrawalAmount(params) {
  return axios.post("/wlynIntegralWithdrawal/countIntegralWithdrawalAmount", 
    params,
  );
}
//统计机构积分总数
export function countOrgIntegralTotal(params) {
  return axios.get("/cancelCode/countOrgIntegralTotal", { params });
}
//获取发放记录分页列表
export function getGrantRecordPage(params) {
  return axios.get("/wlynGrantRecord/getGrantRecordPage", { params });
}
//比较导出获积分体现数据excel
export function compareExportExcel(params) {
  return axios.post("/wlynIntegralWithdrawal/compareExportExcel",  params );
}
//比较导出获积分提现T+3数据excel
export function compareExportExcelByOrgId(params) {
  return axios.get("/wlynIntegralWithdrawal/compareExportExcelByOrgId", {
    params,
  });
}
//导出记录
export function getExportRecordPage(params) {
  return axios.post(`/wlynExportRecord/getExportRecordPage`, params, {
    timeout: 180000,
  });
}

// 创展
export function selectCreateExhibitionList(params) {
  return axios.get("/wlynCreateExhibition/selectCreateExhibitionList", {
    params,
  });
}

//导出批量查询积分提现数据excel
export function exportBatchExcel(params) {
  return axios.post("/wlynIntegralWithdrawal/exportBatchExcel", params);
}

