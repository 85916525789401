
<template>
  <div>
    <el-form inline size="mini" class="form-search">
      <el-form-item label="模糊查询">
        <el-input style="width:360px;" placeholder="请输入标题 / 客户名称 / 机构名称 / 服务商名称" v-model="search.search" clearable
          @input="changeInput">
        </el-input>
      </el-form-item>
      <el-form-item label="" style="margin-left: 20px;">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto">
      <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="title" align="center" label="标题"></el-table-column>
      <el-table-column prop="customerName" align="center" label="客户名称"></el-table-column>
      <el-table-column prop="directAmount" align="center" label="直达金额(元)"></el-table-column>
      <el-table-column prop="releaseTime" align="center" label="发放时间"></el-table-column>
      <el-table-column prop="institutionName" align="center" label="机构名称"></el-table-column>
      <el-table-column prop="providerName" align="center" label="服务商名称" min-width="200px"></el-table-column>
      <el-table-column align="center" label="操作" width="130">
        <template slot-scope="">
          <el-button type="text" icon="el-icon-view" style="color:#333">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display:flex;width:100%">
      <el-pagination style="text-align:center;flex:1" layout="total,sizes,prev, pager, next"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize"
        :current-page="pageNum" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { selectCreateExhibitionList } from '@/api/issueList'
import { baseURL } from '@/utils/http'

export default {
  data() {
    return {
      url: baseURL,
      search: {
        search: '',
      },
      totalCount: 0,
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    changeInput() {
      this.handleCurrentChange(1)
    },
    getList() {
      let param = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // isHide: 1
      };
      Object.assign(param, this.search)
      selectCreateExhibitionList(param)
        .then((response) => {
          if (response.code == 200) {
            this.total = response.data.total;
            this.list = response.data.records;
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";

.form-search {
  min-width: 500px;
  margin: 10px 0;
}

.form-item-box {
  float: left;
  height: 46px;
}

.el-form-item {
  margin: 0;
}

.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}

.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}

.btn-red-hover:hover {
  color: $hoverYellow;
}
</style>